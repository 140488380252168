import { StaticQuery } from "gatsby"
import React from "react"
import styles from "./whyBlockTextColumns.module.css"
import { graphql } from "gatsby"
import { Fragment } from "react"

const WhyBlockTextColumns = (props) => {

  return (
    <StaticQuery
      query={graphql`query WhyBlockTextColumns {
  allContentfulWhyBlockTextColumns {
    nodes {
      column1
      column2
      column3
      buttonBlockTitle
      columnBlockTitle
      id
      topBlockTitle
    }
  }
}
`}
      render={allData => {
        const data = allData.allContentfulWhyBlockTextColumns.nodes[0]
        return (
          <Fragment>

            <div className={`${styles.whyBlockTextColumnsMobile}`}>
              <div className={styles.topBlock}>
                <p className={styles.topBlockTextMobile}>
                  {data.topBlockTitle}
                </p>
              </div>
              <div className={styles.columnsBlock}>
                <h2 className={styles.columnTitleTextMobile}>
                  {data.columnBlockTitle}
                </h2>
                <div className={styles.columnsContainerMobile}>
                  <div className={styles.column1Mobile}>
                    {data.column1}
                  </div>
                  <div className={styles.column2Mobile}>
                    {data.column2}
                  </div>
                  <div className={styles.column3Mobile}>
                    {data.column3}
                  </div>

                </div>
              </div>
              <div className={styles.buttonBlock}>
                <h3 className={styles.callToAcion}>
                  {data.buttonBlockTitle}
                </h3>
                <div className={styles.buttonContainer}>
                  <a className={styles.buttonProductMobile} href="/product">
                    Go to product
                  </a>
                </div>
              </div>
            </div>


            <div className={`${styles.whyBlockTextColumns}`}>
              <div className={styles.topBlock}>
                <p className={styles.topBlockText}>
                  {data.topBlockTitle}
                </p>
              </div>
              <div className={styles.columnsBlock}>
                <h2 className={styles.columnTitleText}>
                  {data.columnBlockTitle}
                </h2>
                <div className={styles.columnsContainer}>
                  <div className={styles.column1}>
                    {data.column1}
                  </div>
                  <div className={styles.column2}>
                    {data.column2}
                  </div>
                  <div className={styles.column3}>
                    {data.column3}
                  </div>

                </div>
              </div>
              <div className={styles.buttonBlock}>
                <h3 className={styles.callToAcion}>
                  {data.buttonBlockTitle}
                </h3>
                <div className={styles.buttonContainer}>
                  <a className={styles.buttonProduct} href="/product">
                    Go to product
                  </a>
                </div>
              </div>
            </div>


          </Fragment>


        )
      }
      }
    />
  )
}

WhyBlockTextColumns.propTypes = {}

WhyBlockTextColumns.defaultProps = {}
export default WhyBlockTextColumns
