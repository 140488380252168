import React from "react"
import styles from "./contactBlock.module.css"
import { Fragment } from "react"


const ContactBlock = () => {
  return (
    <Fragment>

        <div id='contactMobile' className={styles.contactBlock}>
          <h1 className={styles.title}>Get started today</h1>
          <form method="POST" action="https://formspree.io/xpzybyro">
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="name">Name</label>
              <input className={styles.input} placeholder="Name" type="text" name="name"/>
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="name">Company</label>
              <input className={styles.input} placeholder="Company" type="text" name="company"/>
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="name">Email</label>
              <input className={styles.input} placeholder="Email" type="text" name="email"/>
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="name">Message</label>
              <textarea rows="8" className={styles.textarea} placeholder="Write a message..." type="text" name="message"/>
            </div>
            <div className={styles.buttonContainer}>
              <button className={styles.buttonMobile} onClick={()=>{}}>
                Send
              </button>
            </div>
          </form>
        </div>

        <div id='contactD' className={styles.contactBlockDesktop}>
          <h1 className={styles.title}>Get started today</h1>
          <form method="POST" action="https://formspree.io/xpzybyro" className={styles.formContainerDesktop}>
            <div className={styles.topRow}>
              <div className={styles.inputContainerDesktop}>
                <label className={styles.labelDesktop} htmlFor="name">Name</label>
                <input className={styles.input} placeholder="Name" type="text" name="name"/>
              </div>
              <div className={styles.inputContainerDesktop}>
                <label className={styles.labelDesktop} htmlFor="name">Company</label>
                <input className={styles.input} placeholder="Company" type="text" name="company"/>
              </div>
              <div className={styles.inputContainerDesktop}>
                <label className={styles.labelDesktop} htmlFor="name">Email</label>
                <input className={styles.input} placeholder="Email" type="text" name="email"/>
              </div>
            </div>
            <div className={styles.bottomRow}>
              <div className={styles.inputContainer}>
                <label className={styles.labelDesktop} htmlFor="name">Message</label>
                <textarea rows="8" className={styles.textarea} placeholder="Write a message..." type="text" name="message"/>
              </div>
            </div>
            <div className={styles.buttonContainerRightAlign}>
              <button className={styles.button} onClick={()=>{}}>
                Send
              </button>
            </div>
          </form>
        </div>

        <div id='contactT' className={styles.contactBlockTablet}>
          <h1 className={styles.title}>Get started today</h1>
          <form method="POST" action="https://formspree.io/xpzybyro">
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="name">Name</label>
              <input className={styles.input} placeholder="Name" type="text" name="name"/>
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="name">Company</label>
              <input className={styles.input} placeholder="Company" type="text" name="company"/>
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="name">Email</label>
              <input className={styles.input} placeholder="Email" type="text" name="email"/>
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="name">Message</label>
              <textarea rows="8" className={styles.input} placeholder="Write a message..." type="text" name="message"/>
            </div>
            <div className={styles.buttonContainer}>
              <button className={styles.buttonMobile} onClick={()=>{}}>
                Send
              </button>
            </div>
          </form>
        </div>

    </Fragment>
  )
}

ContactBlock.propTypes = {}

ContactBlock.defaultProps = {}
export default ContactBlock
