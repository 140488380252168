import React from "react"

import Layout from "../components/layout"
import WhyBlockImages from "../components/WhyBlockImages/WhyBlockImages.js"
import WhyBlockTextColumns from "../components/WhyBlockTextColumns/WhyBlockTextColumns.js"
import WhyBlockBlueBackground from "../components/WhyBlockBlueBackground/WhyBlockBlueBackground.js"
import ContactBlock from "../components/ContactBlock/ContactBlock.js"

const IndexPage = () => (
  <Layout>
    <WhyBlockImages />
    <WhyBlockTextColumns />
    <WhyBlockBlueBackground />
    <ContactBlock />
  </Layout>
)

export default IndexPage
