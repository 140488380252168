import { StaticQuery } from "gatsby"
import React from "react"
import styles from "./whyBlockImages.module.css"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Fragment } from "react";

const WhyBlockImages = (props) => {

  return (
    <StaticQuery
      query={graphql`query WhyBlockImages {
  allContentfulWhyBlockImages {
    nodes {
      image {
        title
        description
        fluid(quality: 100, maxWidth: 1300) {
          base64
          tracedSVG
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      id
      title
      description
    }
  }
}
`}
      render={allData => {
        const data = allData.allContentfulWhyBlockImages.nodes[0];

        return (
          <Fragment>

              <div className={`${styles.whyBlockImages}`}>
                <div className={styles.textContainer}>
                  <div className={styles.headerContainer}>
                    <h1 className={styles.header}> { data.title } </h1>
                  </div>
                  <div className={styles.descriptionContainer}>
                    <p className={styles.descriptionMobile}>
                      { data.description }
                    </p>
                  </div>
                </div>
                <div className={styles.xxsmall} {...props} maxWidth={471} >
                  <div className={styles.imageContainer}>
                    <Img fluid={data.image.fluid}/>
                  </div>
                </div>
                <div className={styles.xsmall} {...props}  minWidth={472} maxWidth={621} >
                  <div className={styles.imageContainerMobileMedium}>
                    <Img fluid={data.image.fluid}/>
                  </div>
                </div>
                <div className={styles.small} {...props} minWidth={622} >
                  <div className={styles.imageContainerMobileBig}>
                    <Img fluid={data.image.fluid}/>
                  </div>
                </div>
              </div>


              <div className={`${styles.whyBlockImagesTablet}`}>
                <div className={styles.textContainer}>
                  <div className={styles.headerContainer}>
                    <h1 className={styles.header}> { data.title } </h1>
                  </div>
                  <div className={styles.descriptionContainer}>
                    <p className={styles.descriptionMobile}>
                      { data.description }
                    </p>
                  </div>
                </div>
                <div className={styles.imageContainerTablet}>
                  <Img fluid={data.image.fluid}/>
                </div>
              </div>

              <div className={`${styles.whyBlockImagesDesktop}`}>
                <div className={styles.textContainerDesktop}>
                  <div className={styles.headerContainerDesktop}>
                    <h1 className={styles.header}> { data.title } </h1>
                  </div>
                  <div className={styles.descriptionContainerDesktop}>
                    <p className={styles.description}>
                      { data.description }
                    </p>
                  </div>
                </div>
                <div className={styles.imageContainerDesktop}>
                  <Img fluid={data.image.fluid}/>
                </div>
              </div>

          </Fragment>

        )
      }
      }
    />
  )
}

WhyBlockImages.propTypes = {
}

WhyBlockImages.defaultProps = {

}
export default WhyBlockImages
