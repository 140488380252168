import { StaticQuery } from "gatsby"
import React from "react"
import styles from "./whyBlockBlueBackground.module.css"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Fragment } from "react";


const WhyBlockBlueBackground = (props) => {

  return (
    <StaticQuery
      query={graphql`query WhyBlockBlueBackground {
  allContentfulWhyBlockBlueBackground {
    nodes {
      darkBackgroundBoldText
      darkBackgroundLightText
      description
      id
      hashT
      title
      image1 {
        fluid(quality:100) {
          base64
          tracedSVG
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      image2 {
        fluid(quality:100) {
          base64
          tracedSVG
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      image3 {
        fluid(quality:100) {
          base64
          tracedSVG
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      whyBlockFeatures {
        icon {
          fluid(quality: 100, maxWidth: 45) {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
        title
      }
    }
  }
}

`}
      render={allData => {
        const data = allData.allContentfulWhyBlockBlueBackground.nodes[0];

        return (
          <Fragment>

              <div className={styles.mainContainer}>

                <div className={styles.containerLight}>
                  <h1 className={styles.headerLightTextMobile}>
                    { data.title }
                  </h1>
                  <p className={styles.descriptionLightTextDesktop}>
                    { data.description }
                  </p>
                  <div className={styles.featureListContainer}>
                    {
                      data.whyBlockFeatures.map((elm, i) =>{
                        return (
                          <div className={styles.featureBlock}>
                            <div className={styles.iconContainer}>
                              <Img fluid={elm.icon.fluid}/>
                            </div>
                            <div className={`${styles.textContainer} ${ (i === data.whyBlockFeatures.length - 1) ? styles.last : ''}`}>
                              { elm.title }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className={styles.containerImages}>
                    <Img className={styles.image1} fluid={data.image1.fluid}/>
                    <Img className={styles.image2} fluid={data.image2.fluid}/>
                    <Img className={styles.image3} fluid={data.image3.fluid}/>
                  </div>
                  <div className={styles.containerDark}>
                    <div className={styles.darkNormalText}>
                      <p>
                        {
                          data.darkBackgroundLightText
                        }
                      </p>
                    </div>
                    <div className={styles.darkBoldText}>
                      <p>
                        {
                          data.darkBackgroundBoldText
                        }
                      </p>
                    </div>
                    <div className={styles.hashTagContainer}>
                      <h2 className={styles.hashTag}>
                        #{
                        data.hashT
                      }
                      </h2>
                    </div>
                  </div>
                </div>
              </div>


              <div className={styles.mainContainerDesktop}>

                <div className={styles.containerLightDesktop}>
                  <div className={styles.leftContainer}>
                    <h1 className={styles.headerLightText}>
                      { data.title }
                    </h1>
                    <div className={styles.containerImages}>
                      <Img className={styles.image1} fluid={data.image1.fluid}/>
                      <Img className={styles.image2} fluid={data.image2.fluid}/>
                      <Img className={styles.image3} fluid={data.image3.fluid}/>
                    </div>
                  </div>
                  <div className={styles.rightContainer}>
                    <div className={styles.lightRightContainer}>
                      <p className={styles.descriptionLightText}>
                        { data.description }
                      </p>
                      <div className={styles.featureListContainer}>
                        {
                          data.whyBlockFeatures.map((elm, i) =>{
                            return (
                              <div className={styles.featureBlock}>
                                <div className={styles.iconContainer}>
                                  <Img fluid={elm.icon.fluid}/>
                                </div>
                                <div className={`${styles.textContainer} ${ (i === data.whyBlockFeatures.length - 1) ? styles.last : ''}`}>
                                  { elm.title }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    <div className={styles.darkRightContainer}>
                      <div className={styles.darkNormalText}>
                        <p>
                          {
                            data.darkBackgroundLightText
                          }
                        </p>
                      </div>
                      <div className={styles.darkBoldText}>
                        <p>
                          {
                            data.darkBackgroundBoldText
                          }
                        </p>
                      </div>

                    </div>

                  </div>

                </div>
                <div className={styles.hashTagContainer}>
                  <h2 className={styles.hashTagDesktop}>
                    #{
                    <data className="hashT">
                      {data.hashT}
                    </data>
                  }
                  </h2>
                </div>
              </div>


          </Fragment>


        )
      }
      }
    />
  )
}

WhyBlockBlueBackground.propTypes = {
}

WhyBlockBlueBackground.defaultProps = {

}
export default WhyBlockBlueBackground
